import * as React from "react"
import { useQuery, gql } from "@apollo/client"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/ui/layout"
import Seo from "../components/ui/seo"
import ProductsView from "../components/product/ProductsView"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Chip from "@material-ui/core/Chip"
import Typography from "@material-ui/core/Typography"
import { refreshClosedStoreSettings } from "../contexts/actions"

import {
  UserContext,
  FeedbackContext,
  CartContext,
  StoreSettingsContext,
} from "../contexts"

import categoriesFilter from "../helperFunc/categoriesFilter"
import StickyFooter from "../components/ui/stickyFooter"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingTop: "4rem",
    paddingBottom: 0,
    minHeight: "calc(100svh  - 155px)",
  },

  butChip: {
    marginRight: "1rem",
    cursor: "pointer",
  },

  butText: {
    fontSize: "1.2rem",
  },
  but: {
    pointerEvents: "auto",
    paddingLeft: "0.5rem",
    borderRadius: "10rem",
  },
}))

const GET_DETAILS = gql`
  query getCatogDeatils {
    products(pagination: { limit: 99 }) {
      data {
        id
        attributes {
          inStock
          isVisible
        }
      }
    }
  }
`

const Menu = ({ location }) => {
  const [categories, setCategories] = React.useState([])

  const { user, dispatchUser, defaultUser } = React.useContext(UserContext)
  const { feedback, dispatchFeedback } = React.useContext(FeedbackContext)
  const { cart, dispatchCart } = React.useContext(CartContext)
  const { storeSettings, dispatchStoreSettings } =
    React.useContext(StoreSettingsContext)

  const classes = useStyles()

  const params = new URLSearchParams(location.search)
  const catLink = params.get("cat")
  const catLinkKey = location.state

  let data = useStaticQuery(graphql`
    query allMainPageCatProducts {
      allStrapiCategory {
        edges {
          node {
            description
            filterOptions
            id
            images {
              url
            }
            name
            order
            parentCategory
            slug
            strapiId
            products {
              description
              id
              featured
              images {
                url
              }
              name
              order
              pricePrefix
              showQuantity
              price
              section {
                name
                slug
              }
              strapiId
            }
          }
        }
      }
    }
  `)

  const { data: stockData } = useQuery(GET_DETAILS)

  React.useEffect(() => {
    let finalCategoriesOut = categoriesFilter(
      data.allStrapiCategory.edges,
      stockData
    )
    setCategories(finalCategoriesOut)
  }, [data.allStrapiCategory.edges, setCategories, stockData])

  React.useEffect(() => {
    dispatchStoreSettings(refreshClosedStoreSettings())
  }, [dispatchStoreSettings])

  return (
    <Layout
      footerOne={false}
      footerTwo={true}
      footerTwoLarge={true}
      footerTwoWhiteBG={true}
    >
      <Seo title="Menu" />
      <Box classes={{ root: classes.mainContainer }}>
        <ProductsView
          catLink={catLink}
          catLinkKey={catLinkKey}
          categories={categories}
          user={user}
          dispatchUser={dispatchUser}
          defaultUser={defaultUser}
          feedback={feedback}
          dispatchFeedback={dispatchFeedback}
          cart={cart}
          dispatchCart={dispatchCart}
          storeSettings={storeSettings}
          dispatchStoreSettings={dispatchStoreSettings}
        />
      </Box>
      <StickyFooter>
        <Button
          component={Link}
          to="/cart/"
          state={{ isCheckoutStep: false }}
          variant="contained"
          size="large"
          color="primary"
          replace
          classes={{ root: classes.but }}
          disableElevation
        >
          <Chip
            color={cart.length === 0 ? undefined : "secondary"}
            label={cart.length}
            classes={{ root: classes.butChip }}
          ></Chip>
          <Typography variant="body1" classes={{ root: classes.butText }}>
            View Order
          </Typography>
        </Button>
      </StickyFooter>
    </Layout>
  )
}

export default Menu
